import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";
@Component({
  inject: {
    languageService: "languageService",
    eventService: "eventService",
    galleryService: "galleryService"
  }
})
export default class GalleryForm extends mixins(EntityForm) {
  model = {};
  internal_events = [];

  get elements() {
    return [
      {
        legend: this.translations.labels.commonFieldset_generalData,
        type: "fieldset",
        group: "default",
        collapsible: true,
        open: true,
        resetColumns: true,
        elements: [
          {
            id: "idEvent",
            label: this.translations.labels.events,
            type: "select",
            items: this.events,
            required: true
          },
          {
            id: "code",
            label: this.translations.labels.gallery_form_code,
            type: "text",
            required: true,
            rules: "min:4"
          },
          {
            id: "localizations",
            type: "localizations",
            header: this.translations.labels.form_localizations,
            languages: this.languages,
            resetColumns: true,
            elements: [
              {
                id: "title",
                label: this.translations.labels.form_title,
                type: "text",
                required: true,
              },
              {
                id: "description",
                label: this.translations.labels.form_description,
                type: "text",
                required: true,
              },
            ],
          },
        ]
      },
    ];
  }

  get events() {
    return this.internal_events.map((i) => {
      return {
        languages: i.languages,
        label: i.code,
        value: i.id,
      };
    });
  }

  get languages() {
    if (
      this.model.idEvent === undefined ||
      this.model.idEvent === null ||
      this.events === undefined ||
      this.events === null
    )
      return [];
    const event = this.events.filter((e) => e.value == this.model.idEvent)[0];
    if (event != undefined) return event.languages;
    return [];
  }

  get helpLink() {
    return this.translations.pageHelp.gallery;
  }

  async created() {
    this.gallery = await this.galleryService.list();
    this.internal_events = await this.eventService.list();
    this.model.event = this.events.filter(
      (e) => e.value == this.model.idEvent
    )[0];
    this.afterCreate();
  }
}
